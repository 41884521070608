import React from "react";

import MediaQuery from "react-responsive";

type Breakpoint = "xs" | "sm" | "md" | "lg";
const breakpointName = ["xs", "sm", "md", "lg"];
export const breakpoints = {
  xs: 0,
  sm: 768,
  md: 992,
  lg: 1200,
};
type ResponsiveProps = {
  min?: Breakpoint;
  max?: Breakpoint;
};
// Be careful using this function inside render(), changes in window size will
// not necessarily trigger re-render, and can lead to buggy render code
export const getWindowSize = () => window.innerWidth;
export const getBreakpoint = () => {
  const size = window.innerWidth;

  if (size >= breakpoints.lg) {
    return "lg";
  }
  if (size >= breakpoints.md) {
    return "md";
  }
  if (size >= breakpoints.sm) {
    return "sm";
  }
  return "xs";
};
export const windowSizeAtLeast = (minBreakpoint) => {
  return breakpoints[getBreakpoint()] >= breakpoints[minBreakpoint];
};
export const Responsive = ({ min, max, ...rest }: ResponsiveProps) => {
  const minWidth = breakpoints[min || "xs"];
  const overMax = breakpointName[breakpointName.indexOf(max || "lg") + 1];
  const maxWidth = max && max !== "lg" ? breakpoints[overMax] - 1 : undefined;
  return <MediaQuery minWidth={minWidth} maxWidth={maxWidth} {...rest} />;
};
type SizeSwitchProps = {
  xs?: React.ReactNode | (() => React.ReactNode);
  sm?: React.ReactNode | (() => React.ReactNode);
  md?: React.ReactNode | (() => React.ReactNode);
  lg?: React.ReactNode | (() => React.ReactNode);
};
export const SizeSwitch = ({ xs, sm, md, lg }: SizeSwitchProps) => {
  const contents: Array<[React.ReactNode, number]> = [];
  if (xs) contents.push([xs, breakpoints.xs]);
  if (sm) contents.push([sm, breakpoints.sm]);
  if (md) contents.push([md, breakpoints.md]);
  if (lg) contents.push([lg, breakpoints.lg]);
  return contents.reduce(
    (smaller: React.ReactNode, [content, minWidth]): React.ReactNode => (
      <MediaQuery minWidth={minWidth} children={(matches) => (matches ? (typeof content === "function" ? content() : content) : smaller)} />
    ),
    null,
  );
};
