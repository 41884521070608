import React from "react";

import classnames from "classnames";
import isIntuit from "Intuit/isIntuit";
import PropTypes from "prop-types";
import AnalyticsTracking from "Utils/AnalyticsTracking";
import ReactOnRails from "Utils/ReactOnRails";

import Icon from "Common/Icon";

import { RESOURCES_URL, RESOURCE_LINKS } from "./ResourceLinks";

import makeBem from "Utils/makeBem";

const bem = makeBem("DropdownMenu");
const TIMEOUT = 250;
export const HoverMenu = ({ href, title, links, hovered, ...rest }) => (
  <div className={bem("container")} {...rest}>
    <div className={bem("subContainer")}>
      <MenuLink href={href} className={bem("trigger")}>
        <Icon
          icon={isIntuit() ? "question" : "question-circle"}
          style={{
            fontSize: "14px",
          }}
          flavor="solid"
        />
      </MenuLink>
      {hovered && (
        <div
          className={classnames(bem("menu"), bem("setWidth"), {
            [bem("menu", "intuit")]: isIntuit(),
          })}
        >
          <ul className={bem("ul")}>
            {links.map(([linkTitle, href, eventName], i) => (
              <li className={bem("li")} key={i}>
                <MenuLink
                  href={href}
                  target="_blank"
                  className={isIntuit() ? bem("link") : classnames(bem("link"), bem("dropdownItem"))}
                  onClick={() =>
                    AnalyticsTracking.trackEvent(eventName, {
                      location: title,
                    })
                  }
                >
                  {isIntuit() ? linkTitle : <div className={[bem("dropdownItem", "subtext")]}>{linkTitle}</div>}
                </MenuLink>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  </div>
);
HoverMenu.propTypes = {
  href: PropTypes.string,
  title: PropTypes.string.isRequired,
  links: PropTypes.array.isRequired,
  hovered: PropTypes.bool.isRequired,
};
HoverMenu.defaultProps = {
  hovered: false,
};
export default class ResourcesMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hovered: false,
    };
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
  }

  handleMouseEnter() {
    if (this.timer) {
      clearTimeout(this.timer);
    }

    AnalyticsTracking.trackEvent("dropdown_hovered", {
      label: "resources",
      location: "top_bar",
    });
    this.setState({
      hovered: true,
    });
  }

  handleMouseLeave() {
    this.timer = setTimeout(
      () =>
        this.setState({
          hovered: false,
        }),
      TIMEOUT,
    );
  }

  render() {
    return (
      <HoverMenu
        id="ResourcesMenuDropdown"
        href={RESOURCES_URL}
        title="Resources"
        links={RESOURCE_LINKS}
        hovered={this.state.hovered}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      />
    );
  }
}
ReactOnRails.register(
  {
    ResourcesMenu,
  },
  module,
);
const topbarBem = makeBem("TopBar");
export const MenuLink = ({ className, padding = true, componentClass, children, ...rest }: MenuLinkProps) => {
  const component = componentClass || "a";
  const classes = classnames(topbarBem("menuLink"), className, {
    [topbarBem("menuLink", "intuit")]: isIntuit(),
    [topbarBem("menuLink", "padding")]: padding,
  });
  return React.createElement(
    component,
    {
      className: classes,
      ...rest,
    },
    children,
  );
};
