export const RESOURCES_URL = "https://www.simplyinsured.com/advice/";
export const RESOURCE_LINKS = [
  ["FAQ", "https://www.simplyinsured.com/advice/health-insurance-101", "faq_clicked"], // ["Glossary", "todo"],
  [
    "2021 Open Enrollment Guides",
    "https://www.simplyinsured.com/advice/2021/09/15/the-small-business-guide-to-open-enrollment-2021",
    "2021_open_enrollment_guides_clicked",
  ],
  ["Case Studies", "https://www.simplyinsured.com/advice/category/case-studies/", "case_studies_clicked"],
  [
    "Group Health Insurance Buying Guide",
    "https://www.simplyinsured.com/advice/2017/11/02/small-business-health-insurance-guide/",
    "group_health_insurance_buying_guide_clicked",
  ],
];
export const CONTACT_US_LINKS = [
  ["Phone Support", "M-F 7am - 5pm PT", "call_us_clicked"],
  ["Consultation", "Schedule a call with a licensed expert", "calendly_link_clicked"],
];
