import React from "react";

import { mapValues } from "lodash";
import { hot } from "react-hot-loader";

window.ReactOnRails = window.ReactOnRails || require("react-on-rails").default;

type Components = Record<string, React.ComponentType<any>>;

export default {
  register(components: Components, fromModule: any) {
    if (fromModule && fromModule.hot) {
      const withReloading = hot(fromModule);
      components = mapValues(components, (Component) => withReloading(Component));
      if (fromModule.hot.status() === "apply") {
        // No need to re-register on hot reload
        return;
      }
    }
    window.ReactOnRails.register(components);
  },
};
