// @flow
import { getBreakpoint } from "Common/Responsive";

declare let Honeybadger;

type TrackProperties = {
  deprecatedName?: string;
  deprecatedLabel?: string;
  deprecatedValue?: string;
  breakpoint?: string;
};

const AnalyticsTracking = (function () {
  function trackEvent<E extends Record<string, any>>(event: keyof E, properties?: E[keyof E] & TrackProperties): void {
    properties = properties || {};
    properties.breakpoint = getBreakpoint();

    if (process.env.NODE_ENV === "development") {
      console.log(`AnalyticsTracking: ${event}`, properties);
    }

    track_user(event, properties);
  }

  function track_user(event: string, properties?: TrackProperties): void {
    if (typeof window.analytics === "undefined") {
      if (process.env.NODE_ENV !== "development") {
        console.error("Segment library is not defined");
      }
    } else {
      window.analytics.track(event, properties);
    }
  }

  return {
    trackEvent,
    track_user,
  };
})();

export default AnalyticsTracking;
